import axios from "axios";

export default async function gettitleInfo( access_token){
    const fetch = await axios.post('https://api.alphonsoapps.com/tab/titleinfo',{
        method : 'POST',
        headers: {'access_token': access_token},
    })
   const userInfo = fetch.data
   console.log(userInfo);
   return userInfo
}