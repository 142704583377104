import React from 'react'
import { useLocation, useNavigate} from 'react-router-dom'
import tapImg from '../media/newtabintro.png'
import tapLogo from '../media/newTabLogo.png'


export default function Introduction() {
    const navigate = useNavigate()
    const redirectHome = () =>{
        navigate('/')
    }
    // const [user , setUser] = useState('Not Found')

    const search = useLocation().search;
   
    const name = new URLSearchParams(search).get('owner_name');
    const access_token = new URLSearchParams(search).get('session');
    const owner_name = name.split(" ")[0].slice(0 , 7)
    
    
    localStorage.setItem("Alpanso_tab_AccessToken", access_token);

    const tabWelcomeStyle = {
       width : '100px',
       fontWeight : '400',
       color : 'black',
       overflow : 'hidden'
       
    }

    const tabHiStyle = {
      width : '150px',
      fontWeight : '400',
      color : 'black',
    
      
   }

  return (
    <>
    {/* <h1>{user}</h1> */}
     <img className='logo' src={tapLogo} alt='newtapLogo'/> 
      <div className='intropage'>

      <div className='newTabs'><div style={tabHiStyle}>hi !</div> <div ><p style={tabWelcomeStyle}>{owner_name}</p></div></div>
      <div ><p>Get Ready To Make Simplify And<br/><br/>Customer Friendly Your Store</p></div>
    <div><button className='introButton' onClick={redirectHome}>Lets Start !</button></div>  
        
        
    </div>
    </>
   
  )
}
{/* <img src={tapImg} alt='NewTaps'/> */}