import { Card, Grid, TextField,Text,Stack, Button,Select } from '@shopify/polaris'
import React from 'react'
import { useEffect } from 'react';
import {useState, useCallback} from 'react';
import activeStatus from '../controllers/newtabapp';
import activetitleStatus from '../controllers/titleappstatus';
import gettitleInfo from '../controllers/titleInfo';
import sendTitle from '../controllers/titlesend';
import '../css/newtoggle.css'

export default function NewTitle(props) {
    // frist title
    const [titleFrist , setTitleFrist] = useState('title1')
    const titleFristChange = useCallback((title1) => setTitleFrist(title1), []);
  
    // second title
    const [titleSecond , setTitleSecond] = useState('title2')
    const titleSecondChange = useCallback((title) => setTitleSecond(title), []);

    const [status , setStatus] = useState('warning')
   
    // emoji
     const emojies = ['😀','😅','😁','😆','🤣','🥰','🤩','😘','😋','😜','🤑','🤗','🤭','🤐','🤨','😬','😷','🤕','🥵','🥶','🤠','👋','👋','👋','👋',	'👋','👋','👋','👋','👋	','👋',	
     '🤚','🖐','🖐',	'🖐',	'🖐','🖐',	'🖐',	'🖐','😀','😅','😁','😆','🤣','🥰','🤩','😘','😋','😜','🤑','🤗','🤭','🤐','🤨','😬','😷','🤕','🥵','🥶','🤠','👋','👋','👋','👋',	'👋','👋','👋','👋','👋	','👋',	
     '🤚','🖐','🖐',	'🖐',	'🖐','🖐',	'🖐',	'🖐','😀','😅','😁','😆','🤣','🥰','🤩','😘','😋','😜','🤑','🤗','🤭','🤐','🤨','😬','😷','🤕','🥵','🥶','🤠','👋','👋','👋','👋',	'👋','👋','👋','👋','👋	','👋',	
    
     ]

    const [emojiValue, setEmojiValue] = useState('😀');
    
    // mapping emoji list
    const verticalContentMarkup =
      emojies.length > 0 ? (
        <Stack spacing="extraTight" alignment="center">
          {emojies.map((tag) => (
               <span style={{padding : '7px', fontSize: '25px'}} onClick={() => handleEmojiChange(tag)} key={tag}>{tag}</span>
          ))}
        </Stack>
      ) : null;
    
   
    
    const handleEmojiChange = (e) =>{
           setEmojiValue(e)
    }


      // submit title and emoji
    const saveSubmit = async () =>{
        if( titleFrist != '' && titleSecond != '' && emojiValue!=''){
            
             const msg_status = await sendTitle(access_token, titleFrist, titleSecond, emojiValue , selected)
              
             if(msg_status == 200){
                alert('Saving Sucessfully')
             }
        
        }else {
            // if user not enter eny title
            titleFrist == '' ? alert('Please Enter Frist Meassage !') : alert('Please Enter Second Meassage !')
        }
    }



    // effect types
  
    const [selected, setSelected] = useState('blinking');

    const handleSelectChange = useCallback((value) => setSelected(value), []);

    const options = [
      {label: 'Blinking', value: 'blinking'},
      {label: 'Type Writer', value: 'typewriter'},
    
    ];

    
     
    // acess_token
    const access_token = localStorage.getItem('Alpanso_tab_AccessToken')


    const [shop  , setShop] = useState('warning')


    useEffect( () =>{

      async function embed(){
            const app_status = await activetitleStatus(access_token )
            const {status , shop } = app_status
            const titleInfo = await gettitleInfo( access_token)


            const {frist_title, second_title, animation_type, emoji_type } =  titleInfo
            if(frist_title !== undefined ){
              
            setTitleFrist(frist_title)
            setTitleSecond(second_title)
            setEmojiValue(emoji_type)
            setSelected(animation_type)
            }

            setShop(shop)
            if( status == false){
                setStatus( 'success' )
             }else{
                setStatus( 'warning' )
             }
      }
      embed()
                      
     },[])
     
    
    // toggle button
    const handleStatus = async (e) =>{
          
      if  (status === 'warning') {
            
                setStatus( 'success' )

                  function redirect(){
                  window.location.href = 'https://'+props.shop_name+'/admin/themes/current/editor?context=apps&template=$index&activateAppId=1677c721-bded-4c86-8801-044b1e3ca925/title'
                  }
                redirect()
                
                    
       } else{
          
           setStatus( 'warning' )
           alert('you need to go shop embbed off')
           window.location.reload();
          }
     
}

     

  return (
    <div style={{marginTop : '40px'}}>
         <Grid >
            <Grid.Cell columnSpan={{xs: 5, md: 5, lg: 5,xl : 5, sm: 5, xl: 6}} >


              <Card  title="Title Settings" sectioned>
                

                  
                    <Text variant="headingSm" as="h6" >
                             App Embbed
                    </Text>
                  
               <div style={{marginBottom : '30px', marginTop: '20px'}}>{
                status == 'success'?
               <label className="switch">
                  <input type="checkbox" onClick={handleStatus}  checked/>
                  <span className="slider round"></span>
                </label>
                :
                <label className="switch">
                  <input type="checkbox" onClick={handleStatus}/>
                  <span className="slider round"></span>
                </label>
                }
                </div>
                 
              <Text variant="headingSm" as="h6" >
                    First Message
                </Text>
                  <TextField  
                                onChange={titleFristChange}
                                value={titleFrist}
                                helpText="maximum 26 number of characters use in crome tab"
                                >

                </TextField>
                
                <br></br>
                <Text variant="headingSm" as="h6">
                   Second Message
                </Text>
              
                <TextField 
                            value={titleSecond}
                            onChange={titleSecondChange}
                            helpText="maximum 26 number of characters use in crome tab"
                         >
                </TextField>
              <br></br> 
              <Text variant="headingSm" as="h6">
                   Animation Type
                </Text>
              <Select
                  helpText="select animation which apply on title"
                  options={options}
                  onChange={handleSelectChange}
                  value={selected}
                />
              <br></br>
              <br></br>
             <Button primary onClick={saveSubmit}>Save</Button>
              </Card>
             </Grid.Cell>

             {/* emoji section */}
            <Grid.Cell columnSpan={{xs: 5, md: 5, lg: 5,xl : 5, sm: 5, xl: 6}}>

                <Card  title="favicon Settings" sectioned >

                <Text variant="headingSm" as="h6">Favicon</Text>
                
                <TextField
                    helpText='*select emoji here and then click save your tab will update'
                    value={emojiValue}
                    verticalContent={verticalContentMarkup}
                ></TextField>
                <br></br>
                <br></br>
              
                <div style={{ marginTop : '20px',fontSize: '50px'}}>{emojiValue}</div>
                <br></br>
                </Card>
            
            </Grid.Cell>

         </Grid>
    </div>
  )
}












