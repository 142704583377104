import { Button, Card, Grid } from '@shopify/polaris'
import React, { useState } from 'react'

export default function Acccording({id ,Que , Ans}) {

    const [active , setActive] = useState('Active')

    const handleActive = () => {
        
          if(active === 'Active'){
                setActive('Deactive')
          }else{
                setActive('Active')
          }
          
    }

    const faqsStyle = {
        display : 'grid', 
        gridTemplateColumns : '6fr 0.5fr', 
        backgroundColor : 'white', 
        paddingLeft: '40px',
        paddingTop : '15px', 
        paddingBottom : '10px', 
        borderRadius : '20px'
    }


  return (
    
    <div style={{marginTop : '20px',width: '90%'}}>

        <div key={id}  style={faqsStyle}>

            <div >
                 {Que}
            </div>

            <div>
            <Button  onClick={handleActive}>
                  {active === 'Active' ? '+': '-'}
            </Button>
            </div>

                {   active === 'Deactive'  
                ?
                <div style={{borderTop : '1px solid orange', marginTop : '10px' ,paddingTop : '20px'}}>
                      {Ans}
                </div> 
                :    
                 <div></div>
                } 
            
            
        </div>
        
       
        
        
      
    </div>
  )
}
