import React from 'react'
import enTranslations from '@shopify/polaris/locales/en.json';
import { AppProvider, Frame, Layout, Page } from '@shopify/polaris';
import SideNav from '../components/sideNav';
import NewTitle from '../components/NewTitleConfig';
import TopNav from '../components/upperNav';
import { useState } from 'react';
import { useEffect } from 'react';
import getUserAccountInfo from '../controllers/accountinfoController';

export default function Configuration() {

    const heading = {
        fontSize : '30px',
        fontWeight : '800',
        padding : '20px',
        borderBottom : '4px solid orange',
    }
    const [shopName , setShopDomain] = useState('')

    
    const access_token = localStorage.getItem('Alpanso_tab_AccessToken')


    useEffect(() =>{
          
        async  function getInfo(){
                const info = await getUserAccountInfo( access_token )
 
                const {  shop_domain } = info
              
                
                setShopDomain(shop_domain)


         }
         getInfo()
    },[])
    
  return (
    <div>
      <AppProvider i18n={enTranslations} >
            <Frame>
                <TopNav/>
                <SideNav/>
                <Page>
                    <Layout>
                        <Layout.Section>
                            <h1 style={heading}>New Tab Title Emmbed</h1>
                        </Layout.Section>
                        <Layout.Section>
                            <NewTitle shop_name={shopName}/>
                        </Layout.Section>
                    </Layout>
                </Page>
            </Frame>
      </AppProvider>
    </div>
  )
}
