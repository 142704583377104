import axios from "axios";



export default async function sendTitle( access_token, fristMsg , secondMsg , emoji , animation){
   
    const fetch = await axios.post('https://api.alphonsoapps.com/tab/title',{
       method : 'POST',
       headers: {'access_token': access_token},
       body : 
          { 
            fristMsg: fristMsg,
            secondMsg: secondMsg,
            emoji : emoji ,
            animation : animation
          }
 
    })
       const app_status = fetch.status
       console.log(app_status);
       return app_status
 }