const data = [
    {  id : 1,
        Q : 'How to install the ” Alphonso New Browser Tab” on your Store/ online website?',
        S : ' Login into your shopify Store=> Go into apps ( open shopify app store)=> find “   Alphonso new browser tab”  application=>  install “ Alphonsa new browser tab” application onto your Store. It is very easy to install.'
    
    },
    { 
       id : 2,
       Q : ' How to use the application?',
        S : "After completing the installation of the application, when you click on any of the products available on the Home and Catalog/Collection Pages of your store, it will open in a new tab. This application works on desktop/laptop and tablets. It doesn't open up the products in a new page if opened from a browser on a mobile device."

    },
    { 
        id : 3,
        Q : 'What work does this application do?',
         S : "This application opens a product in the new browser tab. Each new tab opened has all the complete features of the online store. You can go ahead and add the product to the cart from the same window and proceed ahead with the payment."

     },
     { 
        id : 4,
        Q : 'How does this application help me?',
         S : "This application helps you to do easy comparisons of the products which you like. You can open them in multiple tabs and do thorough research before making a shopping decision. "

     },
     { 
        id : 5,
        Q : 'Is this application free?',
         S : "Yes,  this application is totally free to use and there are no hidden charges applied"

     },
     { 
        id : 6,
        Q : ' How does this application help me increase sales?',
         S : "This application will help to increase customer engagement in your store and it will give options to make choices before making a buying decision. It will help you in your sales conversion apart from giving a very good user experience to your customers."

     },
     { 
        id : 7,
        Q : 'Will this application open new pages ( e.g.: About Me, Contact Me, Catalog, etc) on a new tab?',
         S : " No, this application will not open pages( e.g.: About Me, Contact Me, Catalog, etc) on a new tab, it only opens Products in a new tab, where, you can do all the functionalities related to Product."

     },
     { 
        id : 8,
        Q : 'How do I uninstall  Alphonso New Browser Tab application?',
         S : "login into your Store=> click on the apps=>  click on app and sales channel=> Press the remove button."

     }  
    
]

export default data;