
import './App.css';
import { BrowserRouter , Route, Router, Routes } from 'react-router-dom';

import Home from './pages/Home';

import Introduction from './pages/Introduction';
import './css/introductionpage.css'
import Configuration from './pages/ConfigurationNewTab';
import Plans from './pages/Plans';

import NotFound from './pages/NotFound';
import Support from './pages/Support';
import FAQ from './pages/FAQ';
import './css/toggle.css'



import OurAppsPage from './pages/OurApps';
import Account from './pages/Account';
import ConfigurationsTitle from './pages/ConfigurationsTitle';



export default function App() {
  return (
  
  
    <BrowserRouter >
        <Routes>
    
        <Route exact path='/' element={<Home/>} />
        <Route exact path='/introduction'  element={<Introduction/>} />
      
        {/* <Route exact path="/ourapps" element={<OurAppsPage/>} /> */}
        <Route exact path='/configuration/newtap' element={<ConfigurationsTitle/>}/> 
         
        <Route exact path ='/support' element={<Support/>} />
        <Route exact path='*' element={<NotFound/>} />
        <Route exact path='/faq' element={<FAQ/>} />
        <Route exact path='/account' element={<Account />}/>
        <Route exact path='/configuration/newTitle' element={<Configuration/>}/>
       </Routes>
    </BrowserRouter>
  
    
  );
}

