import { AppProvider, Key, Page } from '@shopify/polaris'
import React from 'react'
import SideNav from '../components/sideNav'
import TopNav from '../components/upperNav'
import enTranslations from '@shopify/polaris/locales/en.json';
import data from '../components/FaqData.js'
import { render } from 'react-dom';
import Acccording from '../components/Acccording';
import { useLocation } from 'react-router-dom';



export default function FAQ() {

   const headingStyle = {
          borderBottom : '5px solid orange',
          fontSize : '30px',
          fontWeight : '800',
          padding : '20px',
          marginBottom : '40px'
    }
    // const search = useLocation().search;
    // const owner = new URLSearchParams(search).get('owner');

 return (
    <div >

      <AppProvider i18n={enTranslations}>
       
          <TopNav />

          <SideNav/>
        
          <Page>
             <h1 style={headingStyle}>FAQ</h1>
          
              <div style={{marginBottom : '200px'}}>
                {data.map(({id, Q, S }) => (
                  <Acccording key={id} id={id} Que={Q} Ans={S} />
                ))}
              </div>
          
        
          </Page>
          
      </AppProvider>
      
    </div>
  )
}
