import {
    TopBar,
    Frame,
  } from '@shopify/polaris';
import { useState } from 'react';
import { useEffect } from 'react';
import getUserAccountInfo from '../controllers/accountinfoController';
  

  import newTabLogo from '../media/newTabLogo.png'
  
 export default function TopNav() {

    
     const [name , setName] = useState('')
     const [shopName , setShopName ] = useState('')
     const [latter , setLatter] = useState('')

  
    const logo ={
        width: 160,
        topBarSource: newTabLogo,

    }
    
    const userMenuMarkup = (
        <TopBar.UserMenu
        name= {name}
        detail= {shopName}
        initials= {latter}/>
      );

    const topBarMarkUp = (
        <TopBar userMenu={userMenuMarkup} />
    ) 
    
    const access_token = localStorage.getItem('Alpanso_tab_AccessToken')


    useEffect(() =>{
        async function getInfo( ){
          
              const info = await getUserAccountInfo( access_token )
              
              const {shop_domain , email, owner_name} = info

              setName( owner_name )

              const shopTitle = shop_domain.split('.')[0]
              setShopName( shopTitle )
              
              const  fristLatter = owner_name.charAt(0).toUpperCase()
              setLatter( fristLatter )


         }
         getInfo()
    })

    return (
      <div style={{height: '60px'}}>
        <Frame topBar={topBarMarkUp} logo={logo}/>
      </div>
    );
  }