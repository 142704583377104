
import axios from "axios";

export default async function activetitleStatus( access_token ){
   
  const fetch = await axios.post('https://api.alphonsoapps.com/tab/gettitlestatus',{
        method : 'POST',
        headers: {'access_token': access_token },
      
  
   })
   
   const app_status = fetch.data
   console.log(app_status);
   return app_status
      
}


