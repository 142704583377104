import { Banner ,Grid,Text } from '@shopify/polaris'
import React from 'react'
import Homeimg from '../media/new tab home banner.jpg'
import FeatureImg from '../media/new browser tab.png'


export default function Features() {
   
   const heading = {
    marginTop : '40px',
    fontSize : '30px', 
    color : 'black',
    fontWeight : '800',
    padding : '20px',
    marginBottom : '40px'      
   }
   

   const newTapImg = {
     width : '100%',
     height:'300px',
     marginTop : '40px',
     marginBottom : '40px',
     borderRadius : '20px'
   }

   const FeatureImgStyle = {
        width : '100%'
   }


  return (
    <div>
       <img src={Homeimg} style={newTapImg} className='HomeNewTapImg'></img>
                  <Banner title='App Features' status='info'>
                  {/* <h1 style={heading}> App Features</h1> */}
                  <Grid>
                     <Grid.Cell columnSpan={{xs: 6, sm: 6, md: 5, lg: 7,xl : 6}} >
                        <Text variant="headingMd" as='h6' >  1. Open each product on new tab. </Text>
                        <Text variant="headingMd" as='h6' >  2. Each new tab which opens up has full-fledged store functionality.  No need to go back to previous page for completing pending action.</Text>
                        <Text variant="headingMd" as='h6' >3. New tab opens up when user clicks on any product available on the Home and Catalogue/Collection Pages of the store.</Text>
                        <Text variant="headingMd" as='h6' > 4. Increase the session duration of your visitors leading to conversions.</Text>
                        <Text variant="headingMd" as='h6' >5. Single click installation process and very easy to incorporate in your store. No extra code is required. </Text>
            
                        <Text variant="headingMd" as='h6' > 6. Add to Cart and Check out functionality available on each new tab.</Text>
                        <Text variant="headingMd" as='h6' > 7. Available for stores supporting theme 1 and 2.</Text>
                        <Text variant="headingMd" as='h6' > 8. Completely free to use, no hidden charges applied.</Text>
                      </Grid.Cell>
                     
                     <Grid.Cell columnSpan={{xs: 4,md: 4, lg: 6,sm : 5,xl : 5}}>
                        <br></br>
                        <br></br>
                        <img src={FeatureImg} style={FeatureImgStyle}/>

                     </Grid.Cell>
                  </Grid>
                 </Banner>  
                    
    </div>
  )
}

