import axios from "axios";


export default async function sendMail( access_token , msg ) {
     try {
          const support = await   axios.post('https://api.alphonsoapps.com/tab/message',{
            method : 'POST',
            headers: {'access_token': access_token},
            body :  {message: msg}
        })
          const status =  support.status
          return status
          
        } catch (error) {
           console.log(error);
        }
}

