import { AppProvider, Button, Card, Layout, Page, TextField, TopBar } from '@shopify/polaris'
import React, { useCallback, useState } from 'react'
import SideNav from '../components/sideNav'
import enTranslations from '@shopify/polaris/locales/en.json';
import TopNav from '../components/upperNav';
import SupportImg from '../media/alphonso support.jpg'
import sendMail from '../controllers/supportController';
import { useLocation } from 'react-router-dom';





export default function Support() {

    const [support , setSupoort] = useState('')

    const access_token = localStorage.getItem('Alpanso_tab_AccessToken')
   
    const handleChange = useCallback((support) => setSupoort(support), []);

    const submitClickHandle = async () =>{
         if( support != ''){
                
                const msg = await sendMail( access_token,support )
                        
                if( msg === 200 ) alert( 'message sending sucessfully !') 

         }else{
               alert( 'Please Enter Massage !')
         }        
    }
    
 

    const heading = {
        fontSize : '30px',
        fontWeight : '800',
        padding : '20px',
        borderBottom : '4px solid orange',
    }

    const supportEmailHeadingStyle = {
        fontSize : '20px',
        fontWeight :'800',
        marginBottom : '20px',
    }

    const submitButtonStyle = {
        backgroundColor :  'orange',
        color : 'white',
        padding : '10px 40px 10px 40px ',
        border : '1px solid orange',
        borderRadius : '5px',
        fontWeight : '800'

    }

    const supportImgStyle = {
        borderRadius : '20px',
        marginTop : '30px',
        marginBottom : '100px',
        height : '300px'
    }

    const paragraphStyle = {
        borderBottom : '1px solid orange',
        padding : '20px'
    }
    

    
  return (
    <div>
        <AppProvider i18n={enTranslations}>

           <TopNav />
        
           <SideNav/>

           <Page>
            <Layout>
                <Layout.Section>
                    <h1 style={heading}>Support</h1>
                    <p style={paragraphStyle}>Lorem ipsum dolor sit, amet consectetur adipisicing elit. In fugiat sapiente modi exercitationem accusantium id!<br></br>
                     Ex tempore repellendus vitae? Cupiditate eum nostrum fugiat ea officia quo laboriosam animi accusantium magni?</p>
                </Layout.Section>
                
                <Layout.Section>
                   <Card  sectioned>
                   <h1 style={supportEmailHeadingStyle}>Message</h1>
                   <TextField
                    multiline={4}
                    value={support}
                    onChange={handleChange}
                    
                    />
                   <br></br>
                   <Button 
                           style={submitButtonStyle}
                           onClick={submitClickHandle}>
                            submit
                   </Button>
                   </Card>
                </Layout.Section>

                <Layout.Section>
                    <img src={SupportImg} width ='100%' style={supportImgStyle}/>
                </Layout.Section>
              
            </Layout>
           </Page>
          

        </AppProvider>
      
    </div>
  )
}
