
import { Banner, Grid ,Text} from '@shopify/polaris'
import React, { useState } from 'react'
import { useEffect } from 'react'

import activeStatus from '../controllers/newtabapp'

import '../css/toggle.css'





export default function Embbed(props) {

    const [status , setStatus] = useState('warning')
    const [shop , setShop] = useState('')
    const access_token = localStorage.getItem('Alpanso_tab_AccessToken')
    
    

    useEffect( () =>{

        async function embed(){
              const app_status = await activeStatus(access_token)
              const {status , shop } = app_status
              
              setShop(shop)
              if( status == false){
                  setStatus( 'success' )
               }else{
                  setStatus( 'warning' )
               }
        }
        embed()
                        
    },[])

  
  
    const handleStatus = async (e) =>{
          
            if  (status === 'warning') {
                  
                      setStatus( 'success' )

                        function redirect(){
                         window.location.href = 'https://'+props.shop+'/admin/themes/current/editor?context=apps&template=$index&activateAppId=1677c721-bded-4c86-8801-044b1e3ca925/app-embed'
                      }
                      redirect()
            } else{
                
                 setStatus( 'warning' )
                 alert('you need to go shop embbed off')
                 window.location.reload();

           }
           
      }
   
  return (
  <>
   <div style={{marginTop : '40px', marginBottom : '40px'}}>
      <Banner
            title={status == 'success'? 'New Tap Embbed Is On': 'Embbed Is Off'}
            status={status}
            >
                     <Grid>
                       <Grid.Cell columnSpan={{xs: 6, md: 5, lg: 6,xl : 6, sm: 5, xl: 10}}>

                        <Text>
                        you need to frist on this embbed button which is rediret to your store and on embbed click save button and your new tap functionality is on
                        </Text>

                      </Grid.Cell>

                       <Grid.Cell columnSpan={{ xs: 2,sm: 1, xl: 2,md : 1}}>
                              {
                                status == 'success' ? 
                            <div align="center" id="bit00_3">
                              <label className="switch">
                                  <input type="checkbox" onClick={handleStatus} id="checkbox1" checked/>
                                        <div className="slider round">
                                        </div>
                                </label>
                              </div>
                              :
                              <div align="center" id="bit00_3">
                              <label className="switch">
                                  <input type="checkbox" onClick={handleStatus} id="checkbox1" />
                                        <div className="slider round">
                                       </div>
                                  </label>
                            </div>
                             }
                       </Grid.Cell>
                   </Grid>
        </Banner>
  
    </div>
  </>
   
  )
}
