import { AppProvider, Grid, Layout, Page, Text, Form ,Card, TextField} from '@shopify/polaris'
import React, { useState } from 'react'
import enTranslations from '@shopify/polaris/locales/en.json';
import TopNav from '../components/upperNav';
import SideNav from '../components/sideNav';
import { useEffect } from 'react';
import getUserAccountInfo from '../controllers/accountinfoController';



export default function Account() {

    const heading = {
        fontSize : '30px',
        fontWeight : '800',
        padding : '20px',
        borderBottom : '4px solid orange',
    }


    const paragraphStyle = {
        borderBottom : '1px solid orange',
        padding : '20px'
    }
   

    
    const [shopDomain , setShopDomain]  = useState('shopDomain')
    const [shopEmail , setEmail]  = useState('email')
    const [domain , setDomain]  = useState('doamin')
    const [name , setName]  = useState('name')


    const access_token = localStorage.getItem('Alpanso_tab_AccessToken')
    
    

    useEffect(() =>{

        async function  getInfo(){
          
        const shopInfo = await getUserAccountInfo( access_token )
        
        const {shop_domain , email, owner_name} = shopInfo
                setShopDomain(shop_domain)
                setEmail(email)
                setName(owner_name)
                setDomain(shop_domain)
        }
        getInfo()

    },[])



  return (
    <div>
      <AppProvider i18n={enTranslations } >
        <TopNav></TopNav>
        <SideNav></SideNav>
        <Page>
            <Layout>
                 <Layout.Section>
                    <h1 style={heading}>Account</h1>
                    <p style={paragraphStyle}>Manage your account </p>
                </Layout.Section>
              
                <Layout.Section>
                
                  <br></br>
                  <br></br>
                    <Grid>
                       
                        <Grid.Cell columnSpan={{xs: 5, md: 5, lg: 5,xl : 5, sm: 5, xl: 4}}  >
                        <br></br>
                        <br></br>
                        <Text variant="headingSm" as="h6" >App Embbed</Text>
                        <Text variant='headingsm' as='h1'>Manage and preview your account details.</Text>

                        </Grid.Cell>
                         <Grid.Cell columnSpan={{xs: 5, md: 5, lg: 5,xl : 5, sm: 5, xl: 8}} >
                           <Card sectioned>
                            <Form>

                                <TextField label='Shop Domain' disabled placeholder= {shopDomain}
                                ></TextField>
                                <br></br>
                                 <TextField label='Name' disabled     placeholder={name}
                                ></TextField>
                                <br></br>
                                 <TextField label='Email' disabled   placeholder={shopEmail}
                                ></TextField>
                                <br></br>
                               <TextField label='Domain' disabled   placeholder={domain}
                                ></TextField>
                              
                        
                            </Form>
                           </Card>
                        </Grid.Cell> 
                    </Grid>
                   
                
                </Layout.Section>
            </Layout>
        </Page>
      </AppProvider>
    </div>
  )
}
