import {Frame, Navigation,subNavigationItems} from '@shopify/polaris';
import {HomeMinor,  ToolsMajor, PlanMajor, AppsMajor ,  MarketingMajor,  QuestionMarkMajor ,ProfileMajor} from '@shopify/polaris-icons';
import React from 'react';
import { useLocation } from 'react-router-dom';

export default function SideNav() {
  const location =  useLocation()
  
  return (
    <div style={{position : 'fixed'}}>
        <Frame >
          
      <Navigation location={location.pathname} >
        <Navigation.Section
          style={{fontWeight : '800'}}
          items={[
            {
            
              url: '/',
              label: 'Dashbord',
              icon: HomeMinor,
              exactMatch: true
            },
            {
              url: '/configuration/newtap',
              label: 'Configurations',
              icon:  ToolsMajor,
              exactMatch: true,
              subNavigationItems: [
                {
                  url: '/configuration/newtap',
                  disabled: false,
                  label: 'New Tap',
                },
                {
                  url: '/configuration/newtitle',
                  disabled: false,
                  label: 'Tab Title',
                },
              ],
           },
     
            {
              url : '/support',
              label : 'Support',
              icon : MarketingMajor,
              exactMatch : true
            },
            
            {
              url : '/faq',
              label : 'FAQ',
              icon : QuestionMarkMajor,
              exactMatch : true
            }
            ,{
              url: '/account',
              label : 'Account',
              icon: ProfileMajor,
              exactMatch : true
            },
            // {
            //   url : '/ourapps',
            //   label : 'Our Apss',
            //   icon : AppsMajor,
            //   exactMatch : true
            // },
          ]}
        />
      </Navigation>
    </Frame>


    </div>
    
  );
}