import React, { useEffect } from 'react'

import TopNav from '../components/upperNav'
import enTranslations from '@shopify/polaris/locales/en.json';
import {AppProvider, Card, Layout, List, Page, Grid, Frame} from '@shopify/polaris';
import SideNav from '../components/sideNav';

import Embbed from '../components/Embbed';
import Features from '../components/Features';
import { useLocation } from 'react-router-dom';
import getUserAccountInfo from '../controllers/accountinfoController';
import { useState } from 'react';


export default function Home(){

  const [name , setName] = useState('')
  const [shopDomain, setShopDomain] =useState('')
  
  // let query = useQuery()
    const nameHeading = {
      marginTop : '40px',
      fontSize : '25px',
      fontWeight : '800',
      }

      
    const search = useLocation().search;
    
    
    const session = new URLSearchParams(search).get('session');
   
    if(session != null){
      
      localStorage.setItem("Alpanso_tab_AccessToken", session);
    }
    
    const access_token = localStorage.getItem('Alpanso_tab_AccessToken')


    useEffect(() =>{
          
        async  function getInfo(){
                const info = await getUserAccountInfo( access_token )
 
                const { owner_name , shop_domain } = info
              
                setName( owner_name  )
                setShopDomain(shop_domain)


         }
         getInfo()
    },[])

  return (
    <div >
      <AppProvider i18n={enTranslations}>
        
      <Frame>
      <TopNav />   
      
     <SideNav />
     <Page >

          <Layout >
              <Layout.Section >{

                    name ? <h1 style={nameHeading}>Welcome {name} !</h1> : <h1 style={nameHeading}></h1>
                }
              </Layout.Section>
        
        
               <Layout.Section >
                 
                  <Features/>
              
              </Layout.Section>
              
             
            </Layout>
            </Page>
         </Frame>
         
      </AppProvider>
      <br></br>
      <br></br>
      <br></br>
    </div>
    
   
   )
}
