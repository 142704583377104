import axios from "axios";



export default async function getUserAccountInfo( access_token ) {
    const fetch = await axios.post('https://api.alphonsoapps.com/tab/accountinfo',{
        method : 'POST',
        headers: {'access_token': access_token},
    })
   const userInfo = fetch.data
   
   return userInfo
}